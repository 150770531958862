var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.to)?_c('LinkTile',_vm._b({staticClass:"c-content-card__container relative",attrs:{"aria-labelledby":("content-card-" + _vm._uid),"title":_vm.title}},'LinkTile',{ to: _vm.to, target: _vm.target },false),[_c('div',{staticClass:"\n\t\t\tc-content-card\n\t\t\trounded-lg\n\t\t\toverflow-hidden\n\t\t\th-full\n\t\t\tflex flex-col\n\t\t",class:_vm.light ? 'bg-white' : 'bg-primary-medium'},[(_vm.image && _vm.image.cropUrl)?_c('div',{class:[
				'c-content-card__image',
				'relative w-full h-0 overflow-hidden',
				'bg-primary-light ' ],style:({
				'padding-top': (((_vm.image.aspectRatio
						? 1 / _vm.image.aspectRatio
						: _vm.image.height / _vm.image.width) * 100) + "%"),
			})},[_c('UmbracoImageExt',{class:[
					'absolute top-1/2 left-1/2',
					'w-full bg-primary-medium',
					'duration-500 ease-smooth-out' ],attrs:{"widths":[320, 480, 640, 480 * 2, 640 * 2],"alt":_vm.image.altText,"source-url":_vm.image.cropUrl,"source-width":_vm.image.width,"source-height":_vm.image.height,"aspect-ratio":_vm.image.aspectRatio || _vm.image.width / _vm.image.height}})],1):_vm._e(),_vm._v(" "),(_vm.video && _vm.video.provider)?_c('div',{class:[
				'c-content-card__image',
				'relative w-full h-0 overflow-hidden',
				'bg-primary-light' ],style:({
				'padding-top': (((_vm.video.aspectRatio ? 1 / _vm.video.aspectRatio : 3 / 4) *
					100) + "%"),
			})},[_c('CookieInformationBlocker',{staticClass:"absolute top-0 left-0 w-full h-full",attrs:{"categories":_vm.video.cookieCategories}},[_c('VideoPlayer',{staticClass:"absolute top-0 left-0 w-full h-full",attrs:{"picker":_vm.video}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{class:[
				'c-content-card__content',
				'flex flex-col justify-start flex-1',

				{
					'p-md >=768:p-sm >=768:pb-md': _vm.size === 'small',
					'p-md py-sm': _vm.size === 'medium',
					'mb-sm': _vm.$scopedSlots.after,
				} ]},[_c('span',{class:{
					'mb-md': _vm.$scopedSlots.after || !_vm.$scopedSlots.default,
				}},[(_vm.$scopedSlots.before)?_c('div',{staticClass:"text-h8 uppercase mb-2xs >=768:mb-12"},[_vm._t("before")],2):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":("content-card-" + _vm._uid)}},[_vm._t("title",function(){return [(_vm.name)?_c('BaseH3',{domProps:{"textContent":_vm._s(_vm.name)}}):_vm._e()]})],2),_vm._v(" "),(_vm.$scopedSlots.default)?_c('div',{staticClass:"text-body mt-xs"},[_vm._t("default")],2):_vm._e()]),_vm._v(" "),(_vm.$scopedSlots.after)?_c('div',{staticClass:"text-h8 mt-xs bottom-md",class:{ absolute: _vm.afterSlotAbsolute }},[_vm._t("after")],2):_vm._e()])]),_vm._v(" "),_c('BaseButton',{class:[
			'c-content-card__arrow-button',
			'absolute top-full left-1/2',
			'transform -translate-y-1/2 -translate-x-1/2',
			'w-fit z-10 mx-auto' ],attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgArrowIcon',{staticClass:"w-14 h-12 text-white"})]},proxy:true}],null,false,1056738427)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }